<template>
	<div>
		<div :style="{height: (theme.height-300)+'px', overflowY: 'auto', overflowX: 'hidden'}">
			<div v-for="item in materials" :key="item.id" :style="{width: (theme.height/4)+'px', display: 'inline-block', marginLeft: '15px', marginBottom: '15px'}">
				<el-card shadow="hover" :body-style="{ padding: '0px' }">
					<el-image style="width: 100%; height: 110px;" fit="fill" :src="item.content.news_item[0].thumb_url"></el-image>
					<div style="height: 80px; padding: 0 10px; display: flex;  flex-direction: column;  justify-content: space-around;">
						<h3>{{item.content.news_item[0].title}}</h3>
						<p style="color: #7e8081;">{{item.content.news_item[0].digest}}</p>
						<p style="color: #b1b2b3;">{{item.content.update_time | formatDate}}</p>
					</div>
				</el-card>
			</div>
		</div>

		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="[12, 24, 36, 48, 60, 120, 240, 360, 480]"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getWechatDrafts({ ...params, page: 1, perPage: v}) }"
			@current-change="(v) => { getWechatDrafts({ ...params, page: v}) }"
			background>
		</el-pagination>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			async getWechatDrafts (params) {
				const res = await this.$http.get(this.$api.URI_WECHAT_DRAFTS, { params });
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.materials = result.item;
				this.params = {
					...params,
					total: result.total_count,
					perPage: parseInt(result.item_count)
				}
			}
		},
		filters: {
			formatDate (t) {
				const d = new Date(t*1000);
				return '更新于 '+d.getFullYear()+'年'+d.getMonth()+'月'+d.getDate()+'日';
			}
		},
		data() {
			return {
				materials: [],
				params: {
					perPage: 12,
				}
			}
		},
		created () {
			this.getWechatDrafts({ ...this.params, appid: this.$route.query.appid});
		}
	};
</script>

<style scoped lang="scss">
	.materials {
		cursor: pointer;
		margin-bottom: 20px;
	}
	.materials:hover .mask {
		top: 0;
	} 
	.mask {
		color: #fff;
		padding-top: 10px;
		transition: all .3s;
		text-align: center;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,.6);
	}
	.mask p,
	.mask h4 {
		text-align: left;
		line-height: 1.5;
		padding: 0 10px;
	}
	.mask p {
		font-size: 12px;
	}
</style>