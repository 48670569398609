<template>
	<div>
		<div style="display: flex; align-items: center; justify-content: space-between;">
			<el-radio-group :size="theme.size" v-model="params.type" style="margin-bottom: 10px;" @change="getWechatMaterials({ ...params, page: 1, perPage: 12})">
				<el-radio-button v-for="(tool, t) in tools" :label="tool.type" :key="t">{{tool.name}}</el-radio-button>
			</el-radio-group>
			<el-upload ref="upload" :accept="tools_map[params.type].accept" :action="$config.APIHOST+'uploads'" :data="uploadForm" :before-upload="beforeUpload" :on-success="onSuccess" :show-file-list="false">
				<el-button size="mini" type="primary">上传{{tools_map[params.type].name}}</el-button>
				<div slot="tip" class="el-upload__tip">{{tools_map[params.type].tips}}</div>
			</el-upload>
		</div>

		<template v-if="materials.length">
			<div :style="{height: (theme.height-300)+'px', overflowY: 'auto', overflowX: 'hidden'}">
				<div style="margin-left: -15px;" v-if="params.type == 'video'">
					<el-image :style="{width: ((theme.height/6)*1.5)+'px', height: (theme.height/6)+'px', marginLeft: '15px', marginBottom: '15px'}" fit="cover" v-for="item in materials" :key="item.id" :src="item.cover_url" :preview-src-list="[item.cover_url]"></el-image>
				</div>
				<div style="margin-left: -15px;" v-if="params.type == 'image'">
					<el-image :style="{width: (theme.height/5)+'px', height: (theme.height/5)+'px', marginLeft: '15px', marginBottom: '15px'}" fit="cover" v-for="item in materials" :key="item.id" :src="item.url" :preview-src-list="[item.url]"></el-image>
				</div>
				<div style="margin-left: -15px;" v-if="params.type == 'voice'">
					<div class="voice-panel" v-for="(material, m) in materials" :key="m" @click="onSelect(material)">
						<div>{{material.name}}</div>
						<div><i class="el-icon-video-play" style="font-size: 24px; color: #409EFF;"></i></div>
					</div>
				</div>
			</div>
			<el-pagination
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="[12, 24, 36, 48, 60, 120, 240, 360, 480]"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => {getWechatMaterials({ ...params, page: 1, perPage: v})}"
				@current-change="(v) => {getWechatMaterials({ ...params, page: v})}"
				background>
			</el-pagination>
		</template>
		<p v-else style="font-size: 16px; color: #666;">暂无素材</p>
		<el-dialog title="添加视频" :visible.sync="dialogVisible">
			<el-form :size="theme.size" :model="videoForm" :rules="rules" ref="videoForm" label-width="60px" @closed="videoForm = {}; dialogVisible= false;" status-icon>
				<el-form-item label="标题" prop="title">
					<el-input placeholder="请输入视频标题" v-model="videoForm.title" maxlength="30" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="描述" prop="introduction">
					<el-input type="textarea" rows="5" placeholder="请输入视频介绍" v-model="videoForm.introduction" maxlength="300" show-word-limit></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('videoForm')">提交</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import r from '@/utils/http'

	export default {
		computed: {
			...mapState(['theme']),
			tools_map () {
				const dd = [];
				this.tools.forEach((t) => { return dd[t.type] = t});
				return dd
			}
		},
		methods: {
			beforeUpload (file) {
				const { type } = this.params;
				this.uploadForm = { appid: this.$route.query.appid, type: type };
				if (file.size > this.tools_map[type].size * 1024 * 1024) {
					this.$message.error(this.tools_map[type].tips);
					return false;
				}
				if (type == 'video') {
					this.uploadForm.file = file;
					this.dialogVisible = true;
					return false;
				}
			},
			onSuccess (r) {
				!r.code ? this.getWechatMaterials(this.params) : this.$message.error(r.msg)
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return false;
					this.uploadForm = { ...this.uploadForm, ...this.videoForm };
					this.dialogVisible = false;
					const res = await r.post('uploads', this.uploadForm, {headers: {'Content-Type': 'multipart/form-data'}});
					const { code, msg } = res.data;
					!code ? this.getWechatMaterials(this.params) : this.$message.error(msg);
					this.uploadForm = {};
				});
			},
			async getWechatMaterials (params) {
				this.materials = [];
				const res = await this.$http.get(this.$api.URI_WECHAT_MATERIALS, { params });
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.materials = result.item;
				this.params = {
					...params,
					total: result.total_count,
					perPage: parseInt(result.item_count)
				}
			}
		},
		data() {
			return {
				materials: [],
				dialogVisible: false,
				videoForm: {},
				uploadForm: {},
				rules: {
					title: [{ required: true, message: '请输入视频标题', trigger: 'blur' }],
					introduction: [{ required: true, message: '请输入视频介绍', trigger: 'blur' }]
				},
				tools: [
					{name: '图片', type: 'image', size: 10, accept: 'image/gif, image/jpeg, image/png, image/bmp', tips: '支持bmp/png/jpeg/jpg/gif格式，且不超过10M'},
					{name: '音频', type: 'voice', size: 2, accept: '.mp3,.wma,.wav,.amr', tips: '播放长度不超过60s，mp3/wma/wav/amr格式，且不超过2M'},
					{name: '视频', type: 'video', size: 10, accept: '.mp4', tips: '支持MP4格式，且不超过10MB'}
				],
				params: {
					type: 'image',
					perPage: 12,
				}
			}
		},
		created () {
			this.getWechatMaterials({ ...this.params, appid: this.$route.query.appid});
		}
	};
</script>

<style scoped lang="scss">

	.voice-panel {
		width: 300px;
		height: 40px;
		margin-left: 15px;
		display: inline-flex;
		align-items: center;
		border: 1px solid #ccc;
		border-radius: 5px;
		padding: 0 10px;
		justify-content: space-between;
		font-size: 14px;
		color: #666;
		cursor: pointer;
	}
</style>