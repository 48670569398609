<template>
	<div>
		<el-tabs :tab-position="theme.width > 980 ? 'left' : 'top'" :style="{height: (theme.height - 160)+'px'}" v-model="activeTab">
			<el-tab-pane label="草稿箱" name="drafts">
				<wechat-drafts></wechat-drafts>
			</el-tab-pane>
			<el-tab-pane label="素材库" name="materials">
				<wechat-materials></wechat-materials>
			</el-tab-pane>
			<el-tab-pane label="发表记录" name="image_text">
				<wechat-freepublish></wechat-freepublish>
			</el-tab-pane>
			<el-tab-pane label="关键词回复" name="keyword">
				<wechat-keywords></wechat-keywords>
			</el-tab-pane>
			<el-tab-pane label="被关注回复" name="followed">
				<el-card :body-style="{height: (theme.height - 180)+'px', padding: 0, position: 'relative'}">
					<div :style="{height: (theme.height - 220)+'px', padding: '15px', outline: 0, overflowY: 'auto'}">
						<div
							style="display: flex; align-items: center; padding-bottom: 20px;"
							v-for="(followe, f) in followed"
							:key="f"
							@dragover.prevent="handleDragOver($event, followe)"
							@dragstart="handleDragStart($event, followe)"
							@dragenter="handleDragEnter($event, followe)"
							@dragend="handleDragEnd($event, followe)"
							draggable>
							<el-checkbox style="margin-right: 15px;" v-model="followe.checked">{{tools_map[followe.type]}}</el-checkbox>
							<el-input type="textarea" placeholder="请输入内容" v-model="followe.content" v-if="followe.type == 'text'"></el-input>
							<el-image style="width: 100px; height: 100px" fit="cover" :src="followe.content.url" :preview-src-list="[followe.content.url]" v-if="followe.type == 'image'"></el-image>
							<div class="voice-panel" v-if="followe.type == 'voice'">
								<div>{{followe.content.name}}</div>
								<div><i class="el-icon-video-play" style="font-size: 24px; color: #409EFF;"></i></div>
							</div>
							<el-image style="width: 240px; height: 120px; cursor: pointer;" fit="cover" :src="followe.content.cover_url" v-if="followe.type == 'video'"></el-image>
							<div style="margin-left: 15px;">
								<el-popconfirm title="确定删除这条回复内容吗？" @confirm="removeContent(f)">
									<el-button slot="reference" icon="el-icon-delete" circle></el-button>
								</el-popconfirm>
							</div>
						</div>
					</div>
					<div style="height: 40px; line-height: 40px; display: flex; justify-content: space-between; position: absolute; left: 0; right: 0; bottom: 0; border-top: 1px solid #EBEEF5;">
						<div style="display: flex;">
							<div class="followed-item" v-for="(tool, t) in tools" :key="t" @click="addContent(tool.type)"><i :class="tool.icon"></i> {{tool.name}}</div>
							<div style="font-size: 12px; color: #666; padding-left: 15px; margin-left: 15px; border-left: 1px solid #ccc;">Tips：点击选择 or 拖拽可排序回复顺序。</div>
						</div>
						<el-button type="primary" size="mini" icon="el-icon-position" @click="saveFollowed" :disabled="!$utils.checkAuth('wechat/config', 'followed')" plain>保存内容</el-button>
					</div>
				</el-card>
			</el-tab-pane>
			<el-tab-pane label="自定义菜单" name="menus">
				<el-row :gutter="24">
					<el-col :xl="10" :lg="10" :md="10" :sm="10" :xs="24" style="text-align: center;">
						<div class="mobile">
							<img src="@/assets/mobile_head.png"/>
							<div class="m-body"></div>
							<img src="@/assets/bottom_menus.png"/>
							<div class="menu-bar">
								<div class="btn" v-for="(item,indx) in menus" :key="indx" :class="{active:item === formValidate}">
									<div class="child">
										<div class="add" @click="add(item,indx)">
											<i class="el-icon-plus"></i>
											<div class="arrow"></div>
										</div>
										<div class="btns">
											<div class="addadd" v-for="(j,index) in item.sub_button" :key="index" :class="{active:j === formValidate}" @click="getItem(j,index,indx)">
												{{j.name || '二级菜单'}}
											</div>
										</div>
									</div>
									<div class="text" @click="getItem(item,indx,null)">{{item.name || '一级菜单'}}</div>
								</div>
								<div class="btn" v-if="menus.length < 3">
									<div class="text" @click="addText"><i class="el-icon-plus"></i></div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xl="14" :lg="14" :md="14" :sm="14" :xs="24">
						<el-card class="box-card" v-if="checkedMenuId !== null">
							<div slot="header" class="clearfix">
								<span>菜单信息</span>
								<el-button style="float: right; padding: 3px 0" type="text" @click="deltMenus">删除</el-button>
							</div>
							<el-alert show-icon title="已添加子菜单，仅可设置菜单名称" type="success"></el-alert>
							<el-form ref="formValidate" :size="$store.state.theme.size" :model="formValidate" :rules="ruleValidate">
								<el-form-item label="菜单名称" prop="name">
									<el-input v-model="formValidate.name" placeholder="请填写菜单名称" class="spwidth"></el-input>
								</el-form-item>
								<el-form-item label="规则状态" prop="type">
									<el-select v-model="formValidate.type" placeholder="请选择规则状态" class="spwidth">
										<el-option value="click" label="关键字"></el-option>
										<el-option value="view" label="跳转网页"></el-option>
										<el-option value="miniprogram" label="小程序"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="关键字" prop="key" v-if="formValidate.type === 'click'">
									<el-input v-model="formValidate.key" placeholder="请填写关键字" class="spwidth"></el-input>
								</el-form-item>
								<template v-if="formValidate.type === 'miniprogram'">
									<el-form-item label="appid" prop="appid">
										<el-input v-model="formValidate.appid" placeholder="请填写appid" class="spwidth"></el-input>
									</el-form-item>
									<el-form-item label="小程序路径" prop="pagepath">
										<el-input v-model="formValidate.pagepath" placeholder="请填写小程序路径" class="spwidth"></el-input>
									</el-form-item>
									<el-form-item label="备用网页" prop="url">
										<el-input v-model="formValidate.url" placeholder="请填写备用网页" class="spwidth"></el-input>
									</el-form-item>
								</template>
								<el-form-item label="跳转地址" prop="url" v-if="formValidate.type === 'view'">
									<el-input v-model="formValidate.url" placeholder="请填写跳转地址" class="spwidth"></el-input>
								</el-form-item>
								<el-form-item v-if="isTrue">
									<el-button type="primary" @click="submenus('formValidate')">保存并发布</el-button>
								</el-form-item>
							</el-form>
						</el-card>
					</el-col>
				</el-row>
			</el-tab-pane>
		</el-tabs>
		<el-dialog title="素材库" :visible.sync="dialogVisible"  @closed="params = { type: 'image', perPage: 12, page: 1 }; dialogVisible = false" append-to-body>
			<template v-if="materials.length">
				<div class="materials"  style="margin-left: -10px;" v-if="params.type == 'video'">
					<el-image style="width: 200px; height: 150px; cursor: pointer; margin-left: 15px; margin-bottom: 15px;" fit="cover" v-for="(material, m) in materials" :key="m" :src="material.cover_url" @click="onSelect(material)"></el-image>
				</div>
				<div class="materials"  style="margin-left: -10px;" v-if="params.type == 'image'">
					<el-image style="width: 150px; height: 150px; cursor: pointer; margin-left: 15px; margin-bottom: 15px;" fit="cover" v-for="(material, m) in materials" :key="m" :src="material.url" @click="onSelect(material)"></el-image>
				</div>
				<div class="materials"  style="margin-left: -10px;" v-if="params.type == 'voice'">
					<div class="voice-panel" v-for="(material, m) in materials" :key="m" @click="onSelect(material)">
						<div>{{material.name}}</div>
						<div><i class="el-icon-video-play" style="font-size: 24px; color: #409EFF;"></i></div>
					</div>
				</div>
				<el-pagination
					:layout="$config.PAGE_LAYOUT"
					:page-sizes="[12, 24, 36, 48, 60, 120, 240, 360, 480]"
					:page-size="params.perPage"
					:current-page="params.page"
					:total="params.total"
					@size-change="(v) => { getWechatMaterials({ ...params, page: 1, perPage: v}) }"
					@current-change="(v) => { getWechatMaterials({ ...params, page: v}) }"
					background>
				</el-pagination>
			</template>
			<p v-else style="text-align: center; font-size: 16px; color: #666;">暂无素材</p>
		</el-dialog>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import wechatDrafts from './drafts';
	import wechatKeywords from './keywords';
	import wechatMaterials from './materials';
	import wechatFreepublish from './freepublish';

	export default {
		computed: {
			...mapState(['theme']),
			tools_map () {
				const dd = [];
				this.tools.forEach((t) => { return dd[t.type] = t.name});
				return dd
			}
		},
		components: {
			wechatDrafts,
			wechatKeywords,
			wechatMaterials,
			wechatFreepublish
		},
		methods: {
			handleDragStart (e, i) {
				console.log(e);
				this.dragging = i;
			},
			handleDragEnd (e, i) {
				console.log(e, i);
				this.dragging = null;
			},
			handleDragOver (e) {
				e.dataTransfer.dropEffect = 'move';
			},
			handleDragEnter (e, i) {
				e.dataTransfer.effectAllowed = 'move';
				if (i === this.dragging) return false;
				const pages = [...this.followed];
				const src = pages.indexOf(this.dragging);
				const dst = pages.indexOf(i);
				pages.splice(dst, 0, ...pages.splice(src, 1));
				this.followed = pages;
			},
			addContent (t) {
				switch (t) {
					case 'image':
					case 'voice':
					case 'video':
					this.getWechatMaterials({ ...this.params, type: t, appid: this.$route.query.appid});
					break;
					case 'text':
					default: 
						this.followed.push({type: 'text', content: ''});
					break;
				}
			},
			removeContent (i) {
				this.followed.splice(i, 1);
			},
			onSelect (m) {
				this.dialogVisible = false;
				this.followed.push({type: this.params.type, content: m});
				this.params = { type: 'image', perPage: 12, page: 1 };
				this.materials = [];
			},
			async getWechatMaterials (params) {
				const res = await this.$http.get(this.$api.URI_WECHAT_MATERIALS, { params });
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.materials = result.item;
				this.dialogVisible = true;
				this.params = {
					...params,
					total: result.total_count,
					perPage: parseInt(result.item_count)
				}
			},
			// 添加一级字段函数
			defaultMenusData () {
				return {
					type: 'click',
					name: '',
					sub_button: []
				}
			},
			// 添加二级字段函数
			defaultChildData () {
				return {
					type: 'click',
					name: ''
				}
			},
			async getWechatConfig (loading = true) {
				const res = await this.$http.get(this.$api.URI_WECHAT_CONFIG, {params: {appid: this.$route.query.appid}, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.menus = result.menus || [];
				this.followed = result.followed || this.followed;
			},
			saveFollowed () {
				this.postWechatConfig({action: 'followed', appid: this.$route.query.appid, followed: this.followed});
			},
			// 点击保存提交
			submenus (name) {
				if (this.isTrue && !this.checkedMenuId && this.checkedMenuId !== 0) {
					this.postWechatConfig({appid: this.$route.query.appid, menus: this.menus});
				} else {
					this.$refs[name].validate((valid) => {
						if (valid) {
							this.postWechatConfig({appid: this.$route.query.appid, menus: this.menus});
						} else {
							if (!this.check()) return false
						}
					})
				}
			},
			async postWechatConfig (data) {
				let res = await this.$http.post(this.$api.URI_WECHAT_CONFIG, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.checkedMenuId = null;
				this.formValidate = {};
				this.isTrue = false;
				this.$message.success(msg);
			},
			// 点击元素
			getItem (item, index, pid) {
				this.checkedMenuId = index
				this.formValidate = item
				this.parentMenuId = pid
				this.isTrue = true
			},
			// 增加二级
			add (item, index) {
				if (!this.check()) return false
				if (item.sub_button.length < 5) {
					let data = this.defaultChildData()
					let id = item.sub_button.length
					item.sub_button.push(data)
					this.formValidate = data
					this.checkedMenuId = id
					this.parentMenuId = index
					this.isTrue = true
				}
			},
			// 增加一级
			addText () {
				console.log(this.menus)
				if (!this.check()) return false
				let data = this.defaultMenusData()
				let id = this.menus.length
				this.menus.push(data)
				this.formValidate = data
				this.checkedMenuId = id
				this.parentMenuId = null
				this.isTrue = true
			},
			// 判断函数
			check () {
				let reg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
				if (this.checkedMenuId === null) return true
				if (!this.isTrue) return true
				if (!this.formValidate.name) {
					this.$message.warning('请输入按钮名称!');
					return false;
				}
				if (this.formValidate.type === 'click' && !this.formValidate.key) {
					this.$message.warning('请输入关键字!');
					return false;
				}
				if (this.formValidate.type === 'view' && !(reg.test(this.formValidate.url))) {
					this.$message.warning('请输入正确的跳转地址!');
					return false;
				}
				if (this.formValidate.type === 'miniprogram' && (!this.formValidate.appid || !this.formValidate.pagepath || !this.formValidate.url)) {
					this.$message.warning('请填写完整小程序配置!');
					return false;
				}
				return true;
			},
			// 删除
			deltMenus () {
				if (this.isTrue) {
					this.modal2 = true;
				} else {
					this.$message.warning('请选择菜单!');
				}
			},
			// 确认删除
			del () {
				this.parentMenuId === null ? this.menus.splice(this.checkedMenuId, 1) : this.menus[this.parentMenuId].sub_button.splice(this.checkedMenuId, 1)
				this.parentMenuId = null
				this.formValidate = {
					name: '',
					type: 'click',
					appid: '',
					url: '',
					key: '',
					pagepath: '',
					id: 0
				}
				this.isTrue = true
				this.modal2 = false
				this.checkedMenuId = null
				this.$refs['formValidate'].resetFields()
			}
		},
		data() {
			return {
				activeTab: 'keyword',
				followed: [
					{type: 'text', content: '欢迎关注！'},
					{
						type: 'image',
						checked: false,
						content: {
							media_id :  "V-EzopOSqqf6sPyQ0Zimmkzujr8pm6_Up5f9wDM62iKUvndHwAUQF40C-PztcloQ",
							name :  "光明消费图鉴-光明街道.jpg",
							url :  "https://mmbiz.qpic.cn/mmbiz_jpg/teCHibiaP4JPnibZhZVbdqtoOw99OqWrVE5Sjx42riadGnTxUmfYZw4emJXb5wreOekdw5S45HGHjnxVEUibsXEp7Gg/0?wx_fmt=jpeg"
						}
					}, {
						type: 'voice',
						checked: false,
						content: {
							media_id :  "V-EzopOSqqf6sPyQ0Zimmkzujr8pm6_Up5f9wDM62iKUvndHwAUQF40C-PztcloQ",
							name :  "光明消费图鉴-光明街道.jpg"
						}
					}, {
						type: 'video',
						checked: false,
						content: {
							media_id :  "V-EzopOSqqf6sPyQ0Zimmkzujr8pm6_Up5f9wDM62iKUvndHwAUQF40C-PztcloQ",
							name :  "光明消费图鉴-光明街道.jpg",
							url :  "https://mmbiz.qpic.cn/mmbiz_jpg/teCHibiaP4JPnibZhZVbdqtoOw99OqWrVE5Sjx42riadGnTxUmfYZw4emJXb5wreOekdw5S45HGHjnxVEUibsXEp7Gg/0?wx_fmt=jpeg"
						}
					}
				],
				modal2: false,
				dialogVisible: false,
				tools: [
					{name: '文字', type: 'text', icon: 'el-icon-edit-outline'},
					{name: '图片', type: 'image', icon: 'el-icon-picture-outline'},
					{name: '音频', type: 'voice', icon: 'el-icon-headset'},
					{name: '视频', type: 'video', icon: 'el-icon-video-play'},
				],
				formValidate: {
					name: '',
					type: 'click',
					appid: '',
					url: '',
					key: '',
					pagepath: '',
					id: 0
				},
				ruleValidate: {
					name: [
						{ required: true, message: '请填写菜单名称', trigger: 'blur' },
						{ min: 1, max: 14, message: '长度在 1 到 14 个字符', trigger: 'blur' }
					],
					key: [
						{ required: true, message: '请填写关键字', trigger: 'blur' }
					],
					appid: [
						{ required: true, message: '请填写appid', trigger: 'blur' }
					],
					pagepath: [
						{ required: true, message: '请填写备用网页', trigger: 'blur' }
					],
					url: [
						{ required: true, message: '请填写跳转地址', trigger: 'blur' }
					],
					type: [
						{ required: true, message: '请选择规则状态', trigger: 'change' }
					]
				},
				parentMenuId: null,
				menus: [],
				checkedMenuId: null,
				isTrue: false,
				materials: [],
				params: {
					type: 'image',
					perPage: 12,
				}
			}
		},
		created () {
			this.getWechatConfig();
		}
	};
</script>

<style scoped lang="scss">
	*{
		-moz-user-select: none; /*火狐*/
		-webkit-user-select: none; /*webkit浏览器*/
		-ms-user-select: none; /*IE10*/
		-khtml-user-select: none; /*早期浏览器*/
		user-select: none;
	}

	.mobile {
		position: relative;
		display: inline-block;
	}
	.mobile .m-body {
		min-height: 438px;
		background: #f4f5f9;
	}
	.mobile img {
		display: block;
	}

	.menu-bar {
		position: absolute;
		right: 0px;
		bottom: 0px;
	}
	.active {
		border: 1px solid #44b549 !important;
		color: #44b549 !important;
	}
	.btn {
		float: left;
		width: 93px;
		line-height: 48px;
		box-sizing: border-box;
		border: 1px solid #e7e7eb;
		background: #fafafa;
		text-align: center;
		cursor: pointer;
		color: #999;
		position: relative;
	}

	// .btn .child {
	//     display: none
	// }
	// .btn.active .child {
	//     display: block
	// }
	.text {
		height: 48px;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		box-sizing: border-box;
		padding: 0 5px;
	}
	.text:hover {
		color: #000;
	}

	.add {
		position: absolute;
		bottom: 65px;
		width: 100%;
		line-height: 48px;
		border: 1px solid #e7e7eb;
		background: #fafafa;
	}
	.arrow {
		position: absolute;
		bottom: -16px;
		left: 36px;
		/* 圆角的位置需要细心调试哦 */
		width: 0;
		height: 0;
		font-size: 0;
		border: solid 8px;
		border-color:#fafafa #f4f5f9 #f4f5f9 #f4f5f9;
	}
	.btns {
		position: absolute;
		bottom: 115px;
		width: 100%;
		line-height: 48px;
		background: #fafafa;
	}
	.addadd {
		width: 100%;
		line-height: 48px;
		border: 1px solid #e7e7eb;
		background: #fafafa;
		height: 48px;
	}
	.spwidth {
		width: 100%;
	}

	.materials .voice-panel {
		width: 150px;
		margin-left: 10px;
	}

	.voice-panel {
		width: 300px;
		height: 40px;
		display: inline-flex;
		align-items: center;
		border: 1px solid #ccc;
		border-radius: 5px;
		padding: 0 10px;
		justify-content: space-between;
		font-size: 14px;
		color: #666;
		cursor: pointer;
	}
	.followed-item {
		cursor: pointer;
		padding-left: 30px;
	}
</style>