<template>
	<div>
		<div :style="{height: (theme.height-300)+'px', overflowY: 'auto', overflowX: 'hidden'}">
			<el-row :gutter="60">
				<el-col class="materials" v-for="item in materials" :key="item.id" :xs="12" :sm="10" :md="8" :lg="6" :xl="4">
					<el-card shadow="hover" :body-style="{ height: (theme.height/3.2)+'px', padding: '0px' }">
						<el-image style="width: 100%; height: 100%" fit="contain" :src="item.url" lazy></el-image>
					</el-card>
				</el-col>
			</el-row>
		</div>

		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="[12, 24, 36, 48, 60, 120, 240, 360, 480]"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getWechatFreepublish({ ...params, page: 1, perPage: v}) }"
			@current-change="(v) => { getWechatFreepublish({ ...params, page: v}) }"
			background>
		</el-pagination>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			async getWechatFreepublish (params) {
				const res = await this.$http.get(this.$api.URI_WECHAT_FREEPUBLISH, { params });
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.materials = result.item;
				this.params = {
					...params,
					total: result.total_count,
					perPage: parseInt(result.item_count)
				}
			}
		},
		data() {
			return {
				materials: [],
				params: {
					perPage: 12,
				}
			}
		},
		created () {
			this.getWechatFreepublish({ ...this.params, appid: this.$route.query.appid});
		}
	};
</script>

<style scoped lang="scss">
	.materials {
		cursor: pointer;
		margin-bottom: 20px;
	}
	.materials:hover .mask {
		top: 0;
	} 
	.mask {
		color: #fff;
		padding-top: 10px;
		transition: all .3s;
		text-align: center;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0,0,0,.6);
	}
	.mask p,
	.mask h4 {
		text-align: left;
		line-height: 1.5;
		padding: 0 10px;
	}
	.mask p {
		font-size: 12px;
	}
</style>