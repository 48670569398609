<template>
	<div>
		<el-form :inline="true" :model="params" :size="theme.size">
			<el-form-item label="搜索">
				<el-input v-model="params.keywords" placeholder="请输入规则名称or关键词" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary">查询</el-button>
				<el-button type="primary" :disabled="$utils.create('wechat/apps')" @click="drawer = true">添加回复</el-button>
			</el-form-item>
		</el-form>
		<el-alert title="关键词回复说明" type="success" description="优先匹配使用创建的规则。无结果默认搜索文章回复"></el-alert>
		<el-table :data="keywords" :size="theme.size">
			<el-table-column prop="name" label="规则名称"></el-table-column>
			<el-table-column prop="user.name" label="创建用户"></el-table-column>
			<el-table-column prop="keyword" label="关键词"></el-table-column>
			<el-table-column prop="keyword" label="回复内容"></el-table-column>
			<el-table-column prop="created_at" label="创建时间"></el-table-column>
			<el-table-column prop="updated_at" label="更新时间"></el-table-column>
			<el-table-column prop="action" label="更多操作">
				<template slot-scope="scope">
					<el-button type="text" @click="keywordForm = {...scope.row, appid: $route.query.appid}; drawer = true;">编辑</el-button>
					<el-button type="text" @click="onDelete(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getWechatKeywords({ ...params, page: 1, perPage: v}) }"
			@current-change="(v) => { getWechatKeywords({ ...params, page: v}) }"
			background>
		</el-pagination>

		<el-drawer title="添加回复" size="38%" :visible.sync="drawer" @closed="resetForm('keyword_form')" append-to-body>
			<div style="padding: 0 15px;">
				<el-form ref="keyword_form" :size="theme.size" :model="keywordForm" label-width="100px">
					<el-form-item label="规则名称" prop="name" :rules="[{ required: true, message: '请输入规则名称', trigger: 'blur' }]">
						<el-input v-model="keywordForm.name" maxlength="60" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item :label="'关键词'+(k+1)" v-for="(word, k) in keywordForm.keywords" :key="k" :prop="'keywords.' + k + '.keyword'" :rules="{ required: true, message: '请输入关键词'+(k+1), trigger: 'blur' }">
						<el-input v-model="word.keyword">
							<el-select slot="prepend" style="width: 120px;" placeholder="请选择类型" v-model="word.mate" filterable>
								<el-option label="半匹配" value="half"></el-option>
								<el-option label="全匹配" value="full"></el-option>
							</el-select>
							<el-button slot="append" icon="el-icon-plus" v-if="keywordForm.keywords.length === (k+1) && keywordForm.keywords.length < 10" @click="addKeyword({'mate': 'half', 'keyword': ''})"></el-button>
							<el-popconfirm slot="append" title="确定删除这条关键词吗？" @confirm="removeKeyword(k)" v-else>
								<el-button slot="reference" icon="el-icon-delete"></el-button>
							</el-popconfirm>
						</el-input>
					</el-form-item>
					<el-form-item :label="'回复内容'+(c+1)" v-for="(content, c) in keywordForm.contents" :key="c" :prop="'contents.' + c + '.content'" :rules="{ required: true, message: '请输入回复内容'+(c+1), trigger: 'blur' }">
						<div style="display: flex; align-items: center;">
							<el-input type="textarea" v-model="content.content" autosize></el-input>
							<div style="margin-left: 15px;">
								<el-popconfirm title="确定删除这条回复内容吗？" @confirm="removeContent(c)">
									<el-button slot="reference" icon="el-icon-delete" circle></el-button>
								</el-popconfirm>
							</div>
						</div>
					</el-form-item>
					<el-form-item v-if="keywordForm.contents.length < 10">
						<div style="display: flex; margin-top: 15px; border-radius: 5px; background-color: #f1f1f1f1;">
							<div style="padding: 5px 15px; cursor: pointer; color: #666;" v-for="(tool, t) in tools" :key="t" @click="addContent(tool.type)"><i :class="tool.icon"></i> {{tool.name}}</div>
						</div>
					</el-form-item>
					<el-form-item label="回复方式">
						<el-radio-group v-model="keywordForm.way">
							<el-radio :label="0">回复全部</el-radio>
							<el-radio :label="1">随机回复一条</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('keyword_form')">立即保存</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			addKeyword (k) {
				this.keywordForm.keywords.push(k);
			},
			removeKeyword (i) {
				this.keywordForm.keywords.splice(i, 1);
			},
			addContent (t) {
				switch (t) {
					case 'text':
					default: 
						this.keywordForm.contents.push({'type': 'text', 'content': ''});
					break;
				}
			},
			removeContent (i) {
				this.keywordForm.contents.splice(i, 1);
			},
			resetForm (ref) {
				this.drawer = false;
				this.$refs[ref].resetFields();
				this.keywordForm = {
					way: 1,
					keywords: [{'mate': 'half', 'keyword': ''}],
					contents: []
				};
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error('表单提交有误！');
					this.submitWechatKeyword(this.keywordForm);
				})
			},
			async submitWechatKeyword (data) {
				const res = await this.$http.post(this.$api.URI_WECHAT_KEYWORDS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 2000,
					message: msg,
					onClose: () => {
						this.drawer = false;
						this.getWechatKeywords(this.params);
					}
				});
			},
			async getWechatKeywords (params) {
				const res = await this.$http.get(this.$api.URI_WECHAT_KEYWORDS, { params });
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.keywords = result.data;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.name+'】规则吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_WECHAT_KEYWORDS, {params: {action: 'destroy', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getWechatKeywords(this.params);
						}
					});
				})
			}
		},
		data() {
			return {
				keywords: [],
				tools: [
					{name: '图文', type: 'image_text', icon: 'el-icon-document'},
					{name: '文字', type: 'text', icon: 'el-icon-edit-outline'},
					{name: '图片', type: 'image', icon: 'el-icon-picture-outline'},
					{name: '音频', type: 'audio', icon: 'el-icon-headset'},
					{name: '视频', type: 'video', icon: 'el-icon-video-play'},
					{name: '视频号动态', type: 'wechat_channel', icon: 'el-icon-video-play'}
				],
				drawer: false,
				keywordForm: {
					way: 1,
					keywords: [{'mate': 'half', 'keyword': ''}],
					contents: []
				},
				params: {
					perPage: 10,
				}
			}
		},
		created () {
			this.keywordForm = { ...this.keywordForm, appid: this.$route.query.appid };
			this.getWechatKeywords({ ...this.params, appid: this.$route.query.appid });
		}
	};
</script>
